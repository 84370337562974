<template>
  <div v-if="reviewFlow">
    <ol class="breadcrumb breadcrumb-arrows mb-3" aria-label="breadcrumbs">
      <li class="breadcrumb-item">
        <router-link :to="`/reputation/review-flows/`">Surveys</router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page" data-v-step="1">
        <a href="#"> {{ reviewFlow.name }} </a>
      </li>
    </ol>

    <div class="row row-cards mb-3">
      <div class="col-4">
        <div class="card card-sm">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto">
                <span class="bg-yellow text-white avatar"
                  ><!-- Download SVG icon from http://tabler-icons.io/i/users -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                  </svg>
                </span>
              </div>
              <div class="col">
                <div class="font-weight-bold">
                  {{ total_members }}
                </div>
                <div class="small text-muted">total members</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card card-sm">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto">
                <span class="bg-blue text-white avatar">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l5 5l10 -10"></path>
                  </svg>
                </span>
              </div>
              <div class="col">
                <div class="font-weight-medium" v-if="counts">
                  {{ counts.started }}
                </div>
                <div class="text-muted small">reviews started</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card card-sm">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto">
                <span class="bg-green text-white avatar">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-checks"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M7 12l5 5l10 -10"></path>
                    <path d="M2 12l5 5m5 -5l5 -5"></path>
                  </svg>
                </span>
              </div>
              <div class="col">
                <div class="font-weight-bold" v-if="counts">
                  {{ counts.complete }}
                </div>
                <div class="small text-muted">reviews completed</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3" v-if="reviews.length > 0">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Reviews</h3>
          </div>
          <reviews-table :reviews="reviews"></reviews-table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <div class="card" data-v-step="3">
          <div class="card-header d-flex">
            <h3 class="card-title">Survey Groups</h3>
            <button
              v-b-modal.edit-cohort-modal
              class="ms-auto btn btn-sm btn-outline"
            >
              New Group
            </button>
          </div>
          <div v-if="loaded">
            <div
              :class="[tutorial ? '' : 'table-responsive']"
              v-if="cohorts.length > 0"
            >
              <table
                class="table card-table table-vcenter text-nowrap datatable"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Members</th>
                    <th>Reviews Started</th>
                    <th>Reviews Completed</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cohort in cohorts" :key="cohort.id">
                    <td>
                      <router-link
                        :to="`/reputation/review-flow/${reviewFlow.id}/cohort/${
                          cohort.id
                        }${tutorial ? '?tutorial=1' : ''}`"
                        data-v-step="4"
                      >
                        {{ cohort.name }}
                      </router-link>
                    </td>
                    <td>
                      {{ cohort.member_count }}
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td class="d-flex">
                      <button
                        v-b-modal.new-member-modal
                        class="btn ms-auto btn-outline btn-sm"
                        @click="activeCohort = cohort"
                      >
                        Add Member
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="empty" v-else>
              <div class="empty-img">
                <img
                  src="@/assets/illustrations/undraw_Selecting_team.svg"
                  alt=""
                  height="128"
                />
              </div>
              <p class="empty-title">No groups yet</p>
              <p class="empty-subtitle text-muted">
                Create a group to request reviews from your customers.
              </p>
              <div class="empty-action">
                <button
                  v-b-modal.edit-cohort-modal
                  @click="activeCohort = null"
                  class="btn btn-primary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-user-plus"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                    <path d="M16 11h6m-3 -3v6"></path>
                  </svg>
                  Create a cohort
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card" data-v-step="2">
          <div class="card-header d-flex">
            <h3 class="card-title">Get More Reviews</h3>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label class="form-label"> Share your review URL </label>
              <a
                :href="`${business_chatter_url}review/start/${this.reviewFlow.slug}`"
                target="_blank"
                @click.prevent="copyUrl"
              >
                Click to copy
              </a>
            </div>
            <div class="mb-3">
              <label class="form-label"> Share your QR Code </label>
              <img style="max-width: 156px" :src="this.qrCode" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <add-member-modal
      :cohort="activeCohort"
      @saved-member="reloadCohorts"
    ></add-member-modal>
    <edit-cohort-modal
      :reviewFlow="reviewFlow"
      @saved-cohort="reloadCohorts"
    ></edit-cohort-modal>
  </div>
</template>

<script>
import { formatDistanceToNow, parseISO } from "date-fns";

import ReputationManagementService from "../../services/ReputationManagementService";
import AddMemberModal from "./Cohort/AddMemberModal.vue";
import EditCohortModal from "./Cohort/EditCohortModal.vue";
import QRCode from "easyqrcodejs";
import ReviewsTable from "./ReviewFlow/ReviewsTable.vue";

export default {
  components: { AddMemberModal, EditCohortModal, ReviewsTable },
  name: "reputation.review-flow.detail",
  props: {
    tutorial: Boolean,
  },
  data: () => {
    return {
      reviewFlow: null,
      qrCode: null,
      cohorts: [],
      reviews: [],
      activeCohort: null,
      company: null,
      counts: null,
      loaded: false,
      business_chatter_url: ReputationManagementService.business_chatter_url,
    };
  },
  computed: {
    total_members() {
      return this.cohorts.reduce((acc, cur) => acc + cur.member_count, 0);
    },
  },
  methods: {
    relativeDate(date) {
      return formatDistanceToNow(parseISO(date));
    },
    async reloadCohorts() {
      this.cohorts = await ReputationManagementService.getReviewFlowCohorts(
        this.$auth.activeCompany,
        this.$route.params.id
      );
      this.loaded = true;
    },
    copyUrl() {
      navigator.clipboard
        .writeText(
          `${this.business_chatter_url}review/start/${this.reviewFlow.slug}`
        )
        .then(() => {});
    },
    generateQRCode() {
      return new Promise((resolve) => {
        var options = {
          text: `${this.business_chatter_url}review/start/${this.reviewFlow.slug}`,
          dotScale: 0.6,
          dotScaleTiming: 0.75, // Dafault for timing block , must be greater than 0, less than or equal to 1. default is 1
          dotScaleA: 0.75, // Dafault for alignment block, must be greater than 0, less than or equal to 1. default is 1
          crossOrigin: "anonymous",
          logo: this.company.image,
          onRenderingEnd: (_, data) => resolve(data),
        };
        new QRCode(document.createElement("div"), options);
      });
    },
  },
  async mounted() {
    this.reviewFlow = await ReputationManagementService.getReviewFlow(
      this.$auth.activeCompany,
      this.$route.params.id
    );
    await this.reloadCohorts();
    this.company = await ReputationManagementService.getCompany(
      this.$auth.activeCompany
    );
    this.qrCode = await this.generateQRCode();
    this.counts = await ReputationManagementService.getReviewFlowCounts(
      this.$auth.activeCompany,
      this.reviewFlow.id
    );
    this.reviews = await ReputationManagementService.getReviewFlowReviews(
      this.$auth.activeCompany,
      this.reviewFlow.id
    );

    if (this.$route.query.tutorial) {
      this.$tours["getting_started_tutorial"].start();
      this.$emit("tutorial-cohort", this.cohorts[0]);
    } else {
      this.$tours["getting_started_tutorial"].finish();
    }
  },
};
</script>
